import React, { useEffect } from "react";

const PrivacyPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg mt-10 text-sm sm:text-base">
      <br />
      <br />
      <h1 className="text-lg sm:text-2xl font-semibold text-center mb-4">
        Privacy Policy
      </h1>

      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        This Privacy Policy applies to your use of the website of WellMe Pipes,
        hosted at wellmepipes.com, as well as any WellMe Pipes mobile
        applications or other platforms (collectively referred to as "WELLME
        PIPES" or "WEBSITE"). This policy does not apply to third-party websites
        linked to our site or relationships with businesses listed on WellMe
        Pipes. Terms like "WE", "OUR", and "US" refer to WellMe Pipes, while
        "YOU", "YOUR", and "USER" refer to users of the WellMe Pipes website or
        services. "PERSONAL INFORMATION" refers to information that you provide
        that identifies you, such as your name, phone number, email address, or
        other data linked to these identifiers.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Information We Collect and How We Use It
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        We collect, receive, and store your Personal Information to provide a
        safe and customized experience. If you provide third-party account
        credentials, certain content and information from those accounts may be
        shared with WellMe Pipes, subject to this Privacy Policy. You can choose
        not to provide certain information, but this may limit access to some of
        our services.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Account Information for Merchants
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        If you create an account on WellMe Pipes, we may collect Personal
        Information, such as your name, email, and mobile number. We may also
        store sensitive financial information, like payment details, as per
        regulatory requirements.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Customer Information
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        WellMe Pipes stores customer information (address, mobile number, and
        payment details) securely. We do not sell your information to third
        parties and only share information to facilitate order processing or
        customer support.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Cookies
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        Our website uses cookies to enhance your browsing experience and improve
        service quality. Cookies are small data files sent to your device, and
        they help us uniquely identify your browser. You can disable cookies
        through your browser settings, but this may impact your experience on
        our site.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Enforcement
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        WellMe Pipes may use the information collected to investigate and
        enforce compliance with our policies, terms, and this Privacy Policy.
      </p>

      <h2 className="text-lg sm:text-xl font-semibold text-gray-800 mb-3">
        Transfer of Information
      </h2>
      <p className="text-sm sm:text-base text-gray-700 mb-4 text-justify leading-relaxed">
        We may share Personal Information with legal authorities when required
        or with our service providers solely for operational purposes. If WellMe
        Pipes is involved in a merger or acquisition, user information may be
        transferred under the same privacy guidelines.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
