import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./Components/Header";
import HomePage from "./Primex/Home";
import Footer from "./Components/Footer";
import ComingSoon from "./Extra/ComingSoon";
import BniPage from "./Extra/BniPage";
import { useEffect, useState } from "react";
import Primex from "./Routes/Primex";
import ConstaaRex from "./Routes/ConstaaRex";
import Thunder from "./Routes/Thunder";
import WireShield from "./Routes/WireShield";
import Other_Products from "./Routes/Other_Products";
import TermsOfService from "./Components/TermsOfService"; // Import TermsOfService
import PrivacyPolicy from "./Components/PrivacyPolicy"; // Import PrivacyPolicy
import { FaHome } from "react-icons/fa";
import WhatsAppChat from "./Components/WhatsAppChat";
import loader from "./assets/loader.gif";

const App = () => {
  const [showLoader, setShowLoader] = useState(true);
  const gifDuration = 6500; // Duration of the GIF in milliseconds

  useEffect(() => {
    // Hide the loader after gifDuration seconds
    const timer = setTimeout(() => {
      setShowLoader(false);
    }, gifDuration); // Set to the duration of the loader gif

    return () => clearTimeout(timer); // Cleanup timer on unmount
  }, []);

  return (
    <div className="App">
      {showLoader ? (
        // Loader Section (GIF)
        <div className="h-screen w-screen flex items-center justify-center bg-black">
          <img
            src={loader}
            alt="Loading..."
            className="w-full h-full object-cover"
          />
        </div>
      ) : (
        // Main Content
        <div className="App">
          <Header />
          <br />
          <br />
          <br />
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/bni" element={<BniPage />} />
            <Route path="/coming-soon" element={<ComingSoon />} />
            <Route path="/prime-x/*" element={<Primex />} />
            <Route path="/costaa-rex/*" element={<ConstaaRex />} />
            <Route path="/thunder/*" element={<Thunder />} />
            <Route path="/wireshield/*" element={<WireShield />} />
            <Route path="/other-products/*" element={<Other_Products />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />{" "}
            {/* Add Terms of Service Route */}
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />{" "}
            {/* Add Privacy Policy Route */}
          </Routes>
          <Footer />
          <div
            className="fixed bottom-4 right-4 bg-gradient-to-r from-blue-500 to-purple-600 text-white p-2 rounded-full shadow-2xl transition-all duration-300 flex items-center justify-center z-50 transform hover:scale-110 hover:shadow-inner hover:from-blue-600 hover:to-purple-700"
            onClick={() => {
              window.location.href = "/";
            }}
            style={{
              boxShadow:
                "0 8px 15px rgba(0, 0, 255, 0.3), inset 0 -3px 8px rgba(255, 255, 255, 0.5)",
              filter: "drop-shadow(0px 4px 6px rgba(0, 0, 255, 0.3))",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(5px)",
            }}
          >
            <FaHome size={30} />
          </div>
          <WhatsAppChat />
        </div>
      )}
    </div>
  );
};

export default App;
