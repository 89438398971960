import React, { useEffect } from "react";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="max-w-6xl mx-auto p-8 bg-white rounded-lg  mt-10">
      <h1 className="text-3xl font-bold text-center mb-6">Terms of Use</h1>

      <p className="text-gray-700 mb-6 text-justify">
        These Terms of Use ("Terms") govern your access to and use of the WellMe
        Pipes website and services (collectively referred to as "WELLME PIPES"
        or "WEBSITE"). By accessing or using our website, you agree to comply
        with and be bound by these Terms. If you disagree with any part of these
        Terms, please do not use our services.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        Acceptance of Terms
      </h2>
      <p className="text-gray-700 mb-6 text-justify">
        By accessing and using this website, you acknowledge that you have read,
        understood, and agreed to be bound by these Terms, along with any
        additional guidelines or policies posted on our website. These Terms
        apply to all visitors, users, and others who access or use our services.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        User Responsibilities
      </h2>
      <p className="text-gray-700 mb-6 text-justify">
        As a user, you agree to use this website only for lawful purposes and in
        compliance with all applicable local, state, national, and international
        laws. You are responsible for any activity that occurs under your
        account and must keep your account information secure.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        Prohibited Activities
      </h2>
      <p className="text-gray-700 mb-6 text-justify">
        You are prohibited from using our website to engage in activities that
        are harmful, illegal, fraudulent, or violate the rights of others. This
        includes but is not limited to hacking, spamming, transmitting viruses,
        or infringing on intellectual property rights.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        Intellectual Property
      </h2>
      <p className="text-gray-700 mb-6 text-justify">
        All content, logos, trademarks, and intellectual property on this
        website are owned by WellMe Pipes and are protected by intellectual
        property laws. Unauthorized use of our content or marks is strictly
        prohibited.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">Termination</h2>
      <p className="text-gray-700 mb-6 text-justify">
        We reserve the right to terminate or suspend your access to our website
        at any time, without prior notice, for any conduct that we, in our sole
        discretion, believe violates these Terms or is otherwise harmful to our
        interests.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">
        Changes to Terms
      </h2>
      <p className="text-gray-700 mb-6 text-justify">
        We may update these Terms from time to time to reflect changes in our
        practices or relevant laws. Your continued use of the website after any
        modifications indicate your acceptance of the new Terms. We encourage
        you to periodically review these Terms for any updates.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-3">Contact Us</h2>
      <div className="text-gray-700 mb-6 grid gap-8 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
        {/* Manufacturing Section */}
        <div className="bg-gray-100 p-6 flex flex-col justify-between rounded-lg shadow-md">
          <div>
            <h3 className="text-xl font-bold mb-4 text-gray-800">
              Manufactured By:
            </h3>

            {/* Address Section */}
            <div className="mb-4">
              <p className="text-gray-600 font-semibold">
                WELLME PIPES PVT. LTD.
              </p>
              <p className="text-gray-600">
                Khewat No. 213, Khatta No. 252, Killa No. 21 121,
              </p>
              <p className="text-gray-600">
                Bhakharpur Road, Ganaur, Bhakharpur, Sonipat,
              </p>
              <p className="text-gray-600">Haryana, 131101</p>
            </div>
          </div>

          {/* Contact Section */}
          <div>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className=" text-black">Customer Care:</strong>{" "}
              98-559-559-85
            </p>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className=" text-black">Email:</strong>{" "}
              wellmepipes@gmail.com
            </p>
          </div>
        </div>

        {/* Marketing Section */}
        <div className="bg-gray-100 flex flex-col justify-between p-6 rounded-lg shadow-md">
          <div>
            <h3 className="text-xl font-bold mb-4 text-gray-800">
              Marketing By:
            </h3>

            {/* Address Section */}
            <div className="mb-4">
              <p className="text-gray-600 font-semibold">WELLME GROUPS</p>
              <p className="text-gray-600">
                Khewat No. 213, Khatta No. 252, Killa No. 21 121,
              </p>
              <p className="text-gray-600">
                Bhakharpur Road, Ganaur, Bhakharpur, Sonipat,
              </p>
              <p className="text-gray-600">Haryana, 131101</p>
            </div>
          </div>

          {/* Contact Section */}
          <div>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className=" text-black">Customer Care:</strong>{" "}
              99-109-60-448
            </p>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className=" text-black">Email:</strong>{" "}
              wellmegroups@gmail.com
            </p>
          </div>
        </div>

        {/* Customer Support Section */}
        <div className="bg-gray-100 p-6 rounded-lg flex flex-col justify-between shadow-md">
          <div>
            <h3 className="text-xl font-bold mb-4 text-gray-800">
              Customer Support:
            </h3>

            {/* Address Section */}
            <div className="mb-4">
              <p className="text-gray-600 font-semibold">
                ANJANI SUT ENTERPRISES
              </p>
              <p className="text-gray-600">
                Office No: 175A QU-Block Ground Floor
              </p>
              <p className="text-gray-600">Pitampura, Rohini, Delhi-110034</p>
            </div>
          </div>

          {/* Contact Section */}
          <div>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className="  text-black">Customer Care:</strong>{" "}
              958-958-6513
            </p>
            <p className="text-gray-600 font-semibold flex flex-col text-center">
              <strong className=" text-black">Email:</strong>{" "}
              anjanisutenterprisess@gmail.com
            </p>
          </div>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-center my-5 text-gray-800 mb-3">
        Download the WellMe App
      </h2>
      <div className="flex justify-center space-x-4 items-center">
        <a href="https://play.google.com">
          <img
            src="https://havells.com/media/wysiwyg/Google-Play.svg"
            alt="Google Play"
            className="h-12"
          />
        </a>
        <a href="https://www.apple.com/app-store/">
          <img
            src="https://havells.com/media/wysiwyg/Apple-Store.svg"
            alt="App Store"
            className="h-12"
          />
        </a>
      </div>

      <p className="text-center text-gray-500 mt-6">
        © 2024 WellME. All Rights Reserved.
      </p>
    </div>
  );
};

export default TermsOfUse;
